<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Peminjaman Alat : {{ProjectCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProjectCode" v-model="ProjectCode" class="font-weight-bold" readonly/>
                            <label id="errorProjectCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProjectName" v-model="ProjectName" class="font-weight-bold" readonly/>
                            <label id="errorProjectName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Salesman</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Sales" v-model="Sales" class="font-weight-bold" readonly/>
                            <label id="errorSales" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Limit Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="LimitProject" v-model="LimitProject" class="font-weight-bold" readonly/>
                            <label id="errorLimitProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nilai Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoicedProject" v-model="InvoicedProject" class="font-weight-bold" readonly/>
                            <label id="errorInvoicedProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nilai Invoice Terbayar</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoicedPaymentProject" v-model="InvoicedPaymentProject" class="font-weight-bold" readonly/>
                            <label id="errorInvoicedPaymentProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="StatusProject" v-model="StatusProject" class="font-weight-bold" readonly/>
                            <label id="errorStatusProject" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" @input="onChangeStorage()" :disabled="StorageDisabled"/>
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="NoteProject" readonly></textarea>
                            <label id="errorNoteProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan Peminjaman</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="NoteBorrowingTools" class="form-control" rows="2" v-model="NoteBorrowingTools"></textarea>
                            <label id="errorNoteBorrowingTools" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <button class="btn btn-primary float-right btn-addItem" @click="addItemDataClick()" >
                                <i class="fa fa-plus"></i> Add
                            </button>
                            <hr>

                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel" />

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="true"
                                        :pageable="true"
                                        :resizable="true"
                                        :cellClose="cellClose"
                            >

                                <kendo-grid-column  :title="'Action'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center'}"
                                                    :template="this.columnDetailButton"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'project_borrowed_tool_detail_id'"
                                                    :title="'project_borrowed_tool_detail_id'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :hidden="true"></kendo-grid-column>

                                <kendo-grid-column  :field="'status'"
                                                    :title="'Status'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Item'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Item'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                                                                        
                                <kendo-grid-column  :field="'uom'"
                                                    :title="'Satuan'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'created_by'"
                                                    :title="'Dibuat Oleh'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'created_at'"
                                                    :title="'Tgl Dibuat'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'approved_by'"
                                                    :title="'Approve Oleh'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                        
                        <item-form ref="itemDataForm" :saveItemGrid="saveItemGrid"/>
                    </CRow>
                    
                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/borrowing-tools')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import { role_flag_name } from '../../../../infrastructure/constant/variable.js';

import borrowingToolsServices from '../Script/BorrowingToolsServices.js';
import ItemDataForm from './ItemDataForm.vue';

export default {
    name: 'BorrowingToolsForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'item-form' : ItemDataForm,
    },
    props: ['reload', 'addClick'],
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            //Detail
            Id: this.$route.params.id,
            ProjectId: '',
            ProjectCode: '',
            ProjectName: '',
            Sales: '',
            LimitProject: '',
            InvoicedProject: '',
            InvoicedPaymentProject: '',
            StatusProject: '',
            NoteProject: '',

            StorageData: [],
            Storage: '',
            StorageDisabled: false,
            NoteBorrowingTools: '',
            StatusData: [],
            Status: '',

            //grid
            ItemGridData: [],
            ItemSchemaModel: {
                status: { type: "string", editable: false },
                project_borrowed_tool_detail_id: { type:"number", editable:false },
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                quantity: { type: "number", editable: true, validation: { required:true, min:1 } },
                notes: { type: "string", editable: true, validation: { required:true } },
                created_by: { type: "string", editable: false },
                created_at: { type: "string", editable: false },
            },

            RoleFlagStatus: false,
        }   
    },
    async mounted () {
        this.$loading(true);
        $(".btn-addItem").show();
        $(".save-button").show();
        document.getElementById("NoteBorrowingTools").readOnly = false;
        
        //GetRoleFlagStatus For Approve/Reject Button
        var roleId = this.$cookies.get('RoleID');
        var roleFlagData = await borrowingToolsServices.getRoleFlagById(roleId);
        var roleFlagStatus = roleFlagData.filter(obj => { return obj.flag_name == role_flag_name.peminjamanAlat_Approver });
        this.RoleFlagStatus = roleFlagStatus[0].flag_status;

        //Approve/Reject
        var vueComponent = this;
        var grid = this.$refs.gridItem.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#ApproveButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            var detailData = borrowingToolsServices.approverDataConverter(dataItem, "Approved");

            vueComponent.updateStatusClick(dataItem.project_borrowed_tool_detail_id, detailData);
        })

        GridElement.on("click", "#RejectButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            var detailData = borrowingToolsServices.approverDataConverter(dataItem, "Rejected");

            vueComponent.updateStatusClick(dataItem.project_borrowed_tool_detail_id, detailData);
        })

        //Create Form
        if(this.FormType == 'Add'){
            var projectData = await borrowingToolsServices.GetDetailProject(this.Id)

            this.ProjectId = projectData.project_id
            this.ProjectCode = projectData.project_code;
            this.ProjectName = projectData.project_name;
            this.Sales = projectData.sales_name;
            this.StatusProject = projectData.status;
            this.NoteProject = projectData.notes;

            this.LimitProject = projectData.project_detail.limit;
            this.InvoicedProject = projectData.invoiced_projects == null || projectData.invoiced_projects == 0 ? 0 : projectData.invoiced_projects;
            this.InvoicedPaymentProject = projectData.invoiced_payment_projects == null || projectData.invoiced_payment_projects == 0 ? 0 : projectData.invoiced_payment_projects;

            this.StorageData = await borrowingToolsServices.GetStorageData();
            this.Storage = '';
            this.NoteBorrowingTools = '';

            this.StatusData = [
                { value:'New', label:'New'}
            ];
            this.Status = this.StatusData.find(c => c.value == 'New');;

            this.SaveType = 'Add';

            this.StorageDisabled = false;
        } 
        else {
            var data = await borrowingToolsServices.GetProjectBorrowedToolDetail(this.Id)            
            var projectData = await borrowingToolsServices.GetDetailProject(data.project_id)

            this.ProjectId = data.project_id
            this.ProjectCode = data.project_code;
            this.ProjectName = data.project_name;
            this.Sales = projectData.sales_name;
            this.StatusProject = projectData.status;
            this.NoteProject = projectData.notes;

            this.LimitProject = data.limit;
            this.InvoicedProject = data.invoiced_projects == null || data.invoiced_projects == 0 ? 0 : data.invoiced_projects;
            this.InvoicedPaymentProject = data.invoiced_payment_projects == null || data.invoiced_payment_projects == 0 ? 0 : data.invoiced_payment_projects;

            this.StorageData = await borrowingToolsServices.GetStorageData();
            this.Storage = this.StorageData.find(c => c.value == data.storage_id);
            this.NoteBorrowingTools = data.notes;

            var detailData = globalfunc.objectToArrayConverter(data.detail, "BorrowingTools-ItemDetailGet");
            this.ItemGridData = detailData;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_borrowing_tools');
            this.Status = this.StatusData.find(c => c.value == data.status);
            
            this.SaveType = 'Edit';

            if(data.status == 'Finish'){
                $(".btn-addItem").hide();
                document.getElementById("NoteBorrowingTools").readOnly = true;
            }

            if(this.View == 'true'){
                $(".save-button").hide();
            }
            
            this.StorageDisabled = true;
        }
        
        this.$loading(false);
    },
    methods: {
        columnDetailButton(e){
            var button = `
                <div class="btn-group">
                    <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" >
                        Approve </span>
                    </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger btn-sm rounded" id="RejectButton" >
                        Reject </span>
                    </button>
                </div>
            `;
            var disabledbutton = `
                <div class="btn-group">
                    <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" disabled>
                        Approve </span>
                    </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger btn-sm rounded" id="RejectButton" disabled>
                        Reject </span>
                    </button>
                </div>
            `;
            var clearbutton = `
                <div class="btn-group">
                    <button type="button" class="btn btn-secondary btn-sm rounded k-grid-delete k-button-icontext" style="width:120px" id="ClearButton" >
                        Clear </span>
                    </button>
                </div>
            `;
            return e.project_borrowed_tool_detail_id != null ? (this.RoleFlagStatus ? (e.status == 'New' ? button : disabledbutton) : '') : clearbutton;
        },
        addItemDataClick(){
            if (this.Storage.value === undefined) {
                this.$swal("Info", "Harap untuk memilih gudang yang akan dipakai untuk peminjaman item", "info");
            }
            else {
                this.$refs.itemDataForm.addClick(this.Storage.value)
            }
        },
        onChangeStorage() {
            if (this.SaveType == 'Add') {
                this.ItemGridData = [];
            }
        },
        async refreshDetailItemData(project_borrowed_tool_id){
            var data = await borrowingToolsServices.GetProjectBorrowedToolDetail(project_borrowed_tool_id)
            var detailData = globalfunc.objectToArrayConverter(data.detail, "BorrowingTools-ItemDetailGet");
            this.ItemGridData = detailData;
        },
        cellClose(e){
            var newDataItem = e.sender.dataItem($(e.container).parent());

            var itemData = this.ItemGridData;
            var oldDataItem = itemData.filter(obj => { return obj.project_borrowed_tool_detail_id == newDataItem.project_borrowed_tool_detail_id });

            if (newDataItem.project_borrowed_tool_detail_id != null) {
                if (newDataItem.status == 'New') {
                    if (oldDataItem[0].quantity != newDataItem.quantity) {
                        // if (oldDataItem[0].created_by != this.$cookies.get('ContactName')) {
                        //     this.$swal("Info", "Quantity tidak dapat diubah", "info");
                        //     this.ItemGridData = itemData;
                        // }
                        this.$swal("Info", "Quantity tidak dapat diubah", "info");
                        newDataItem.quantity = oldDataItem[0].quantity;
                        newDataItem.dirtyFields = {};
                    }
                }
                else {
                    if (oldDataItem[0].quantity != newDataItem.quantity) {
                        this.$swal("Info", "Quantity tidak dapat diubah karena status sudah '"+ newDataItem.status +"'", "info");
                        newDataItem.quantity = oldDataItem[0].quantity;
                        newDataItem.dirtyFields = {};
                    }
                    if (oldDataItem[0].notes != newDataItem.notes) {
                        this.$swal("Info", "Catatan tidak dapat diubah karena status sudah '"+ newDataItem.status +"'", "info");
                        newDataItem.notes = oldDataItem[0].notes;
                        newDataItem.dirtyFields = {};
                    }
                }
            }
        },
        saveItemGrid(data) {
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            var BorrowedToolsItemData = borrowingToolsServices.gridDataConverter(gridData);

            var inputData = borrowingToolsServices.gridDataConverter(data);
            BorrowedToolsItemData = BorrowedToolsItemData.concat(inputData);

            this.ItemGridData = BorrowedToolsItemData; 
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (this.Storage.value === undefined) {
                this.errorShow('errorStorage');
            }

            var itemData = this.$refs.gridItem.kendoWidget().dataSource._data;
            if(this.ItemGridData.length == 0){
                this.$swal("Error", "Detail Item yang akan dipinjam harus diisi terlebih dahulu", "error");
                this.Error++;
            }
            else {
                for(var i=0;i<itemData.length;i++){
                    if(itemData[i].quantity < 1){
                        this.$swal("Error", "Quantity pada Item '" + itemData[i].product_code + "' dengan UOM '" + itemData[i].uom + "' tidak boleh lebih kecil dari 1", "error");
                        this.Error++;
                        break;
                    }
                    if(itemData[i].notes == ''){
                        this.$swal("Error", "Catatan pada Item '" + itemData[i].product_code + "' dengan UOM '" + itemData[i].uom + "' tidak boleh kosong", "error");
                        this.Error++;
                        break;
                    }
                }
            }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;
                var itemDetailData = globalfunc.objectToArrayConverter(gridItem, "BorrowingTools-ItemDetailSave");

                const borrowingTools = {
                    project_id: parseInt(this.ProjectId),
                    storage_id: this.Storage.value,
                    notes: this.NoteBorrowingTools,
                    status: this.Status.value,
                    detail: itemDetailData
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : borrowingTools
                    }

                    borrowingToolsServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/borrowing-tools' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.Id),
                        data : borrowingTools
                    }
                    
                    borrowingToolsServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/borrowing-tools'
                                }
                            });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        async updateStatusClick(detailId, detailData){
            this.$loading(true);

            const variables = {
                id: parseInt(detailId),
                data : detailData
            }
            
            borrowingToolsServices.updateStatusQuery(variables).then(res => {
                this.$loading(false);
                this.$swal("Info", response.successUpdate, "success");
                this.refreshDetailItemData(this.Id);
            }).catch(error => {
                this.$loading(false);
                var errorMessage = globalfunc.errorExtractor(error)
                this.$swal("Error", errorMessage, "error");
            })
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>